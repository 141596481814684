/* latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/oswald-10.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/oswald-5.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/source-sans-3-7.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

a {
  --bs-link-color-rgb: [96, 160, 178];
  text-decoration: none;
}

.navbar {
  /* What the docs use */
  // --bs-navbar-padding-y: 30px;
}

.nav-link {
  --bs-nav-link-color: #fff;
  --bs-navbar-active-color: #60a0b2;
  --bs-navbar-nav-link-padding-x: 30px;
  text-transform: uppercase;
}

.navbar-brand {
  --bs-navbar-brand-margin-end: 50px;
}

.row > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgb(14, 14, 16);
    background: linear-gradient(
      160deg,
      rgba(14, 14, 16, 1) 0%,
      rgba(20, 15, 30, 1) 100%
    );
    z-index: -1;
    // background-attachment: fixed;
  }
}

h1 {
  display: inline-block;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 0px;
  letter-spacing: 0.05em;
  color: #fff;
  text-shadow: rgba(255, 255, 255, 0.6) 0px 0px 41px;
}

h2 {
  display: table;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  background: -webkit-linear-gradient(160deg, #6441a5 0%, #60a0b2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0px;
  margin-top: 0px;
  letter-spacing: 0.05em;
}

p.tagline {
  display: table;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(160deg, #6441a5 0%, #60a0b2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
  letter-spacing: 0.05em;
}

h1 + p {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.05em;
  text-shadow: rgba(255, 255, 255, 0.6) 0px 0px 41px;
}

p {
  color: #fff;
  line-height: 1.5em;
  letter-spacing: 0.01em;
}

.card-text {
  color: #000;
}

.form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
}

.form-switch > label {
  padding-left: 10px;
  line-height: 2em;
}
